import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1024 1024">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M502.983978,595.985596 
	C509.744507,588.506226 515.305969,580.655457 519.129028,571.655090 
	C519.808960,570.054504 520.675537,568.692017 522.707031,568.692749 
	C541.338196,568.699097 559.969299,568.696472 578.399536,568.696472 
	C580.000366,571.020020 578.908813,572.929810 578.369751,574.756226 
	C563.818359,624.060364 531.602173,656.469910 482.643127,670.795288 
	C443.529816,682.239807 404.492615,679.581116 368.292542,659.751953 
	C327.511200,637.413330 302.881500,602.931213 296.599152,556.311401 
	C292.034760,522.440247 296.648254,490.028076 315.028961,460.736816 
	C337.756287,424.518768 370.278290,402.156036 412.871735,395.636841 
	C447.724854,390.302338 481.374451,394.123535 512.549072,411.655060 
	C544.080078,429.386902 565.834534,455.383881 576.871826,490.049683 
	C577.067078,490.662842 577.152771,491.310852 577.248962,491.755188 
	C576.227356,493.664886 574.617188,493.388000 573.161865,493.383423 
	C556.498352,493.330811 539.834045,493.139008 523.171997,493.260345 
	C518.718750,493.292725 516.123840,491.908997 513.554321,487.972198 
	C495.914001,460.944855 470.769012,447.337463 438.428040,447.328857 
	C401.236298,447.318939 368.806854,469.003967 357.064056,504.043121 
	C338.525696,559.359436 369.870667,610.773499 420.281616,620.943970 
	C451.743378,627.291443 479.719696,619.379700 502.983978,595.985596 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M728.264587,621.043579 
	C718.561462,654.090698 689.373047,676.148193 655.299805,676.349060 
	C609.311829,676.620239 563.323120,676.787964 517.334595,676.957886 
	C513.765076,676.971069 510.134064,677.137573 506.309204,675.849121 
	C507.742889,673.238586 510.258911,672.962769 512.214233,672.090332 
	C534.522400,662.136414 553.577271,647.894653 568.867798,628.837891 
	C571.981201,624.957642 575.288269,624.020447 580.033569,624.151672 
	C601.163025,624.735779 622.343445,625.870544 643.417419,623.644592 
	C658.942749,622.004700 670.620361,608.396423 671.664978,592.039429 
	C672.707703,575.709961 661.998962,560.515930 646.686035,556.586609 
	C643.121155,555.671875 639.527771,555.257446 635.824158,555.264587 
	C599.995178,555.333496 564.165955,555.332947 528.336853,555.303223 
	C526.073364,555.301331 523.665710,555.910034 521.133667,554.129761 
	C522.811951,543.956482 523.079529,533.550110 521.837036,523.091248 
	C521.324097,518.773438 520.531006,514.539124 519.445312,510.363831 
	C518.634949,507.247620 519.644043,505.944550 522.756897,505.958862 
	C551.583374,506.091248 580.410278,506.163269 609.236145,506.375824 
	C616.562561,506.429840 623.872864,506.921631 631.178955,505.821228 
	C644.882751,503.757233 656.026917,491.278412 656.651489,477.126068 
	C657.266357,463.195374 647.856995,450.171631 634.552734,446.820221 
	C625.709229,444.592468 616.664551,445.454773 607.685303,445.534149 
	C597.357727,445.625397 587.028076,445.688873 576.704956,445.971222 
	C572.244324,446.093231 570.304810,442.911621 568.020813,440.123932 
	C553.182007,422.012604 535.082886,408.204712 513.654175,398.763062 
	C511.526886,397.825775 509.404694,396.875854 507.301544,395.886353 
	C507.051056,395.768494 506.936310,395.362122 506.725677,395.038910 
	C507.436310,393.036316 509.203918,393.760406 510.512756,393.759216 
	C555.174133,393.717865 599.843567,393.204742 644.494324,393.894836 
	C680.391052,394.449677 707.113281,418.238007 713.535217,446.580353 
	C720.114685,475.617920 713.605530,501.022156 691.172241,521.528381 
	C687.218689,525.142334 687.281189,526.164429 691.792603,528.710388 
	C715.665405,542.182678 727.262024,563.364258 730.385254,589.944336 
	C731.605347,600.327637 731.012817,610.540588 728.264587,621.043579 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M599.612671,306.775330 
	C602.204102,294.003143 604.737976,281.647339 607.417297,268.582336 
	C603.116760,270.275513 600.582764,273.159607 597.742249,275.477722 
	C571.027710,297.279266 544.332764,319.106110 517.809875,341.139618 
	C514.179199,344.155731 511.873535,344.499023 508.090088,341.365021 
	C480.250336,318.303986 452.229919,295.460999 424.242828,272.578125 
	C422.861115,271.448395 421.700165,269.925079 419.355865,269.440948 
	C418.627563,273.364258 420.165527,276.936218 420.839813,280.515778 
	C422.872681,291.307556 425.102203,302.065063 427.453033,312.792572 
	C428.190948,316.159912 427.205811,317.222321 423.840485,317.366150 
	C413.104980,317.825012 412.991089,317.951569 410.691406,307.646942 
	C405.039246,282.319794 399.500122,256.967407 393.945374,231.618576 
	C393.672546,230.373444 393.183746,229.024002 394.772186,227.200974 
	C419.563751,247.417862 444.368652,267.645630 470.031891,288.573364 
	C483.983917,262.190643 497.519623,236.595139 511.036957,211.034393 
	C514.000793,212.217773 514.424194,214.346909 515.300537,216.052078 
	C526.723206,238.277817 538.087219,260.533752 549.491089,282.769135 
	C553.101624,289.808929 553.353821,289.921387 559.423645,285.000885 
	C581.814575,266.849457 604.139832,248.617020 626.520569,230.452942 
	C628.010803,229.243454 629.245300,227.440048 631.952698,227.560287 
	C631.986450,233.440704 630.099609,238.966202 628.941040,244.545502 
	C624.274170,267.019897 619.206665,289.411255 614.572144,311.892090 
	C613.718201,316.034332 611.978821,317.746918 607.910095,317.235046 
	C607.746094,317.214447 607.577087,317.232208 607.410461,317.233459 
	C597.186707,317.308960 597.186707,317.308990 599.612671,306.775330 
M536.766479,293.698181 
	C528.571777,277.984741 520.377136,262.271301 512.312317,246.806854 
	C510.129852,247.108902 509.596100,248.171036 509.074036,249.210373 
	C501.087402,265.110504 493.137604,281.029266 485.100220,296.903687 
	C483.773010,299.525024 484.560852,301.265839 486.654297,302.918365 
	C494.617462,309.204468 502.589325,315.481415 510.452698,321.890930 
	C512.408752,323.485352 513.765686,323.338379 515.606873,321.764771 
	C521.927307,316.362823 528.178528,310.848694 534.826355,305.871887 
	C539.389221,302.455963 540.641296,299.054565 536.766479,293.698181 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M450.000000,368.074951 
	C441.676056,368.069672 433.849396,367.938995 426.029266,368.108154 
	C422.192993,368.191132 420.303711,366.847809 419.674286,362.898926 
	C417.887421,351.688904 417.763550,351.701477 429.223145,351.698120 
	C486.321472,351.681458 543.419800,351.666107 600.518127,351.658081 
	C608.404358,351.656952 608.404358,351.677002 606.841187,359.442078 
	C605.058960,368.295288 604.902466,368.261108 595.792664,367.830902 
	C590.157837,367.564758 584.496338,367.900635 578.846680,367.908905 
	C536.064453,367.971619 493.282227,368.020111 450.000000,368.074951 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M546.814697,718.248535 
	C550.804382,723.638916 550.933228,729.077026 548.044128,734.478455 
	C546.835205,736.738647 546.667236,738.161621 548.608582,740.087158 
	C553.449036,744.887939 554.872803,750.724304 552.037170,756.999573 
	C549.165466,763.354675 543.726074,765.628418 536.928711,765.348450 
	C532.443604,765.163696 527.941956,765.222961 523.452759,765.351379 
	C520.366760,765.439697 518.810974,764.347412 518.821533,761.067200 
	C518.867432,746.757080 518.814880,732.446716 518.811829,718.136414 
	C518.811462,716.396912 519.094116,714.327881 521.103943,714.249146 
	C528.862366,713.945435 536.739075,712.799194 544.195557,716.143982 
	C545.077148,716.539368 545.772949,717.348877 546.814697,718.248535 
M540.908203,756.720825 
	C544.746338,754.060791 545.843628,750.539001 543.747559,746.364563 
	C541.923645,742.732361 531.665039,740.458252 528.392639,742.852356 
	C527.809448,743.279053 527.425293,744.166687 527.235840,744.914185 
	C524.580261,755.388977 527.679382,758.945251 538.306885,757.650757 
	C538.952087,757.572144 539.573181,757.294983 540.908203,756.720825 
M527.074524,727.634888 
	C527.212952,734.922241 527.820374,735.387756 535.212524,734.782593 
	C539.467834,734.434326 541.522766,732.075867 541.621948,728.050354 
	C541.719421,724.091187 538.341736,721.830872 532.707520,721.429749 
	C528.720337,721.145935 526.667847,722.559387 527.074524,727.634888 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M318.492401,766.110962 
	C297.838135,762.846069 291.135925,742.533691 298.049713,728.127502 
	C303.328918,717.127319 312.657501,712.852600 324.707062,713.615662 
	C334.652100,714.245361 343.460388,720.242432 346.859406,729.616882 
	C347.538300,731.489136 349.855774,734.269287 346.580902,735.606934 
	C343.742615,736.766174 340.907593,736.438782 339.340057,732.604736 
	C336.258636,725.067871 330.344482,721.300232 322.162506,721.253479 
	C312.372986,721.197510 305.311066,727.381348 303.688293,737.381042 
	C302.279907,746.059875 307.782440,754.726379 316.475952,757.521545 
	C325.285339,760.353943 335.001129,756.573669 338.700714,748.073303 
	C340.793701,743.264343 343.637787,743.050720 347.675201,744.333679 
	C347.810547,757.199158 335.098389,766.778931 318.492401,766.110962 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M415.297180,332.576263 
	C411.799744,318.427612 412.529083,321.082977 423.828888,320.887817 
	C431.993256,320.746765 440.170593,320.587982 448.324310,320.901215 
	C452.770081,321.072021 455.293732,319.740295 457.125244,315.504944 
	C460.658142,307.335114 460.916473,307.446838 467.812805,312.778748 
	C473.739502,317.361053 472.914062,317.086853 470.601807,324.047333 
	C466.952606,335.032440 460.255219,338.654724 448.972046,337.375153 
	C439.758240,336.330261 430.329437,337.119263 420.997192,337.215576 
	C417.898560,337.247559 415.778961,336.364624 415.297180,332.576263 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M569.051758,316.014709 
	C570.366699,319.912628 572.836060,320.931671 576.477722,320.873230 
	C586.780701,320.707916 597.089355,320.938385 607.395020,320.877869 
	C611.271118,320.855103 612.802490,322.003754 611.841553,326.231567 
	C609.053833,338.496460 611.067871,336.856659 598.607849,337.027985 
	C587.970642,337.174255 577.329041,337.030518 566.692810,337.210907 
	C563.258972,337.269135 561.098145,336.135223 559.675781,332.929565 
	C558.130188,329.446106 556.236511,326.103516 554.313416,322.805145 
	C552.536621,319.757690 552.401245,317.236084 555.769653,315.230469 
	C556.614929,314.727142 557.309875,313.969116 558.066223,313.319122 
	C564.797485,307.534454 564.796997,307.533905 569.051758,316.014709 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M489.817871,743.373718 
	C487.859833,743.383667 486.362183,743.399231 484.864532,743.399475 
	C476.727692,743.401062 474.287842,746.776978 476.385864,754.915161 
	C477.070190,757.569580 479.000671,757.584778 481.042114,757.539917 
	C485.023834,757.452515 489.006714,757.363708 492.984833,757.182434 
	C495.992004,757.045471 497.788513,758.103882 497.795166,761.380066 
	C497.801178,764.339722 496.396637,765.442017 493.454681,765.387146 
	C486.468018,765.256775 479.475830,765.265930 472.488739,765.386658 
	C469.314056,765.441406 467.971771,764.204834 467.990540,760.993164 
	C468.072235,747.015442 468.084137,733.036926 468.023254,719.059082 
	C468.008240,715.608582 469.141449,713.793823 472.916931,713.869507 
	C479.570068,714.002930 486.228943,713.902893 492.884674,713.831848 
	C495.810364,713.800659 497.647705,714.565186 497.542206,718.049988 
	C497.445312,721.249878 495.736786,721.931396 493.033325,721.899170 
	C487.743286,721.836182 482.452026,721.880676 477.196289,721.880676 
	C475.768646,725.472839 476.419037,728.815125 476.418579,732.093262 
	C476.418274,734.211487 477.874268,734.975647 479.756317,734.933594 
	C483.078552,734.859253 486.409271,734.799988 489.716034,734.505371 
	C492.712830,734.238464 495.212799,734.635559 495.571716,738.181091 
	C495.952026,741.938049 494.181946,743.822998 489.817871,743.373718 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M720.033691,724.086060 
	C718.462952,722.833557 717.234009,721.566101 715.739014,720.994507 
	C711.831970,719.500549 708.939026,721.299072 706.845154,724.513489 
	C704.601196,727.958191 706.375854,730.536682 709.049622,732.677124 
	C711.863403,734.929688 715.463196,735.386536 718.735535,736.569519 
	C727.162170,739.615906 731.062744,745.104553 730.281616,753.034119 
	C729.585754,760.097900 724.217651,764.645386 715.265442,765.754517 
	C707.242615,766.748474 700.142212,762.760986 697.615967,755.739868 
	C696.913391,753.787048 695.507385,751.195740 698.465393,750.218079 
	C701.010864,749.376892 703.846680,748.919434 705.448792,752.733032 
	C707.876282,758.511230 713.752991,760.372742 718.540955,757.417358 
	C723.467041,754.376648 723.749451,749.578064 719.157776,746.042480 
	C716.459167,743.964417 713.220703,743.107117 710.011292,742.179443 
	C705.738953,740.944580 701.993591,738.916870 699.691101,734.867188 
	C696.730469,729.660034 696.904785,723.251526 700.635925,718.923096 
	C704.866333,714.015625 710.472656,712.192810 716.818359,713.405518 
	C723.231506,714.631165 727.684753,718.292175 729.287720,724.852478 
	C729.637024,726.282104 730.005981,728.049866 728.115601,728.714783 
	C726.047180,729.442383 723.764160,729.786926 722.080994,727.917175 
	C721.223572,726.964478 720.847656,725.578369 720.033691,724.086060 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M369.695129,731.002075 
	C369.693970,726.507751 369.803162,722.508911 369.656769,718.519470 
	C369.541046,715.366089 370.788116,713.907654 373.994415,713.937927 
	C380.817993,714.002441 387.643005,713.990784 394.466736,713.930969 
	C397.529785,713.904114 398.466400,715.416382 398.412415,718.296997 
	C398.354431,721.392639 396.649292,721.736633 394.156982,721.629944 
	C390.169250,721.459229 386.172638,721.494202 382.179749,721.445923 
	C379.456482,721.413025 377.892944,722.278503 377.917023,725.484924 
	C378.009003,737.714111 376.238556,735.505554 387.583313,735.658569 
	C389.247406,735.681030 390.912537,735.705872 392.576019,735.671570 
	C395.402039,735.613281 396.318481,737.116089 396.292969,739.755005 
	C396.268280,742.304443 395.139221,743.367737 392.648560,743.325684 
	C389.320740,743.269470 385.984436,743.456665 382.664001,743.292053 
	C379.277985,743.124023 377.952515,744.444336 377.843628,747.904724 
	C377.536163,757.677429 377.417664,757.675476 386.955322,757.696228 
	C389.784821,757.702332 392.614990,757.733521 395.443634,757.683411 
	C398.223999,757.634216 399.285767,759.010193 399.225189,761.697510 
	C399.169067,764.189697 398.079590,765.334656 395.574341,765.322632 
	C388.250946,765.287598 380.925934,765.235107 373.604187,765.351624 
	C369.973206,765.409363 369.675751,763.220947 369.686920,760.463745 
	C369.726074,750.809875 369.697876,741.155640 369.695129,731.002075 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M626.901550,757.626282 
	C629.921204,757.763000 633.022278,756.897644 633.180969,761.106201 
	C633.277161,763.656433 632.564697,765.325806 629.643372,765.319885 
	C622.989868,765.306458 616.335754,765.303467 609.682800,765.378418 
	C606.610107,765.413025 605.687500,763.816406 605.693604,761.003113 
	C605.724243,746.863708 605.698425,732.723999 605.631409,718.584717 
	C605.617615,715.666321 606.331543,713.738831 609.794006,713.860779 
	C612.908936,713.970642 614.264343,715.352661 614.246338,718.492310 
	C614.187134,728.805115 614.240845,739.118591 614.258850,749.431824 
	C614.273132,757.612305 614.278076,757.613220 622.432922,757.636108 
	C623.763733,757.639832 625.094482,757.632019 626.901550,757.626282 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M668.264160,757.630981 
	C671.383667,757.634338 674.050842,757.709900 676.711304,757.609192 
	C679.395874,757.507446 680.749146,758.634094 680.806885,761.362793 
	C680.863953,764.056335 679.661987,765.347351 676.922485,765.330078 
	C670.428833,765.289062 663.934326,765.281860 657.440979,765.340759 
	C654.529724,765.367126 653.321533,764.267517 653.386597,761.163818 
	C653.683167,747.019348 653.961426,732.869507 653.173706,718.728638 
	C653.012207,715.828979 653.793945,713.894409 657.245422,713.866577 
	C660.611816,713.839417 661.240173,715.573303 661.486755,718.549744 
	C662.396790,729.534424 661.803772,740.510193 661.495605,751.476990 
	C661.359314,756.325500 662.992493,758.353149 668.264160,757.630981 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M428.446594,723.553101 
	C428.431274,732.789734 428.378357,741.611572 428.389557,750.433228 
	C428.398712,757.654175 428.432617,757.652222 435.414764,757.650757 
	C438.244598,757.650146 441.076111,757.722961 443.903778,757.647278 
	C446.679688,757.572998 447.804504,758.993042 447.870270,761.593018 
	C447.940155,764.354736 446.603577,765.410645 443.881958,765.367126 
	C437.558044,765.265808 431.229767,765.246582 424.906525,765.365356 
	C421.616150,765.427063 420.600891,763.955078 420.624451,760.824646 
	C420.730988,746.676086 420.738708,732.526001 420.651794,718.377258 
	C420.631775,715.120483 422.027496,713.900513 425.128906,713.915955 
	C428.583954,713.933167 428.717804,716.074646 428.508148,718.646606 
	C428.386719,720.135803 428.454254,721.640442 428.446594,723.553101 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M573.653137,735.000183 
	C573.646423,729.021667 573.692810,723.542419 573.613586,718.064880 
	C573.571350,715.149597 574.775269,713.802002 577.765381,713.868774 
	C580.668579,713.933716 581.998840,715.242859 581.991882,718.138489 
	C581.957275,732.581177 581.947449,747.023926 581.961670,761.466675 
	C581.964661,764.556396 580.312744,765.397644 577.501343,765.327637 
	C574.777039,765.259827 573.631714,764.124451 573.650391,761.398865 
	C573.709473,752.766357 573.660889,744.133118 573.653137,735.000183 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M582.835938,815.078125 
	C582.145447,815.273926 581.824280,815.380737 581.496460,815.408081 
	C572.725098,816.139709 572.092773,815.585388 573.681763,807.258179 
	C574.409302,803.445374 574.136292,799.152222 577.021057,796.063965 
	C580.152283,797.259521 578.117920,799.732727 579.591064,801.364929 
	C582.894165,801.506775 586.898865,801.212646 587.848694,806.046448 
	C588.669128,810.221497 586.900085,813.104675 582.835938,815.078125 
M577.038696,807.488708 
	C575.865845,809.810059 576.732178,811.470703 578.979919,812.203552 
	C581.156616,812.913330 582.888489,811.750183 584.016907,809.924561 
	C584.992493,808.346130 584.988159,806.642883 583.285339,805.533081 
	C580.939331,804.004089 578.876282,804.426514 577.038696,807.488708 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M543.668274,809.987793 
	C541.134399,810.172180 538.971680,809.534790 536.860657,810.352661 
	C538.696960,812.489441 538.696960,812.489441 543.622803,811.708008 
	C543.064392,815.399841 539.007996,816.911743 535.294556,814.782410 
	C532.900818,813.409790 531.784607,811.222595 532.046326,808.558594 
	C532.338135,805.587769 533.748779,803.050964 536.727966,802.119446 
	C539.426941,801.275574 542.460754,800.826721 544.517578,803.447449 
	C546.150146,805.527588 547.829285,808.021423 543.668274,809.987793 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M479.157867,800.914185 
	C485.545654,802.278015 487.812592,804.541504 487.013489,808.704956 
	C486.057770,813.684265 482.248138,816.192017 476.583130,815.073669 
	C475.066925,814.774292 473.649597,815.613220 471.701599,814.643555 
	C472.786743,808.469055 472.985992,801.961975 475.831360,795.919678 
	C478.850372,797.209595 477.719971,799.466064 479.157867,800.914185 
M476.216278,810.006897 
	C477.353821,812.609497 479.324249,812.737793 481.484558,811.567688 
	C483.243408,810.615112 484.024658,808.939880 483.501068,806.966492 
	C483.072357,805.350525 481.670685,804.473511 480.126617,804.596497 
	C477.388947,804.814575 476.181122,806.647583 476.216278,810.006897 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M439.776184,813.600647 
	C436.398193,816.376038 433.127045,816.224060 430.120453,813.715820 
	C427.585297,811.600952 427.676605,808.782166 428.954315,805.985474 
	C430.548401,802.496155 433.408112,801.280212 437.099121,801.684998 
	C440.253784,802.030945 441.954102,804.038147 442.081329,806.942993 
	C442.239471,810.553589 439.259857,809.835510 437.038788,809.958313 
	C435.766846,810.028748 434.420349,809.793152 433.284790,810.783569 
	C434.345551,813.132141 435.888031,812.549744 437.473175,811.891846 
	C438.877228,811.309143 440.178101,810.980225 439.776184,813.600647 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M691.899414,802.935913 
	C694.759949,805.950623 694.507812,808.981079 692.688354,811.877136 
	C689.374329,817.152100 684.237305,815.025146 678.945374,815.022827 
	C679.142151,808.372131 679.909790,802.161743 683.285339,796.083069 
	C684.441467,800.732971 688.034180,801.681152 691.899414,802.935913 
M685.437073,804.908142 
	C683.660706,806.795288 681.013733,808.602417 683.642334,811.496521 
	C685.534729,813.580078 687.596252,812.440979 689.296875,810.907349 
	C690.488525,809.832703 690.874390,808.332764 690.377075,806.814697 
	C689.697937,804.741455 688.127075,804.107422 685.437073,804.908142 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M389.921143,806.091064 
	C391.815704,802.250732 394.774048,801.229187 398.382751,801.737122 
	C401.461517,802.170471 403.255402,804.160461 403.072113,807.164062 
	C402.884674,810.235840 400.319855,809.887695 398.131561,809.670837 
	C396.462555,809.505493 394.849518,809.539001 393.419128,810.627441 
	C395.335510,812.398193 395.335510,812.398193 401.589142,811.999512 
	C399.615082,815.560974 396.927887,815.971497 394.048523,815.395447 
	C390.076019,814.600586 388.725525,811.561768 389.921143,806.091064 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M460.649933,812.519165 
	C462.602478,812.873169 463.946503,810.943359 465.694519,811.874390 
	C464.629059,815.675171 459.824066,816.849670 456.553986,814.152649 
	C453.910553,811.972534 453.741913,807.249634 456.198578,804.201904 
	C458.504211,801.341553 463.551758,800.626099 466.058929,802.889771 
	C467.474915,804.168152 468.424683,805.833313 467.945892,807.781616 
	C467.350952,810.202637 465.182922,809.718323 463.417847,809.832458 
	C461.810028,809.936401 460.102905,809.211609 458.614502,810.409241 
	C458.468353,811.731262 459.464539,812.057617 460.649933,812.519165 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M651.237976,811.157715 
	C651.425110,808.652588 653.242615,805.645203 649.758240,805.014465 
	C646.733093,804.466858 645.770630,807.072449 645.161926,809.756042 
	C644.710876,811.744385 645.055481,814.455322 641.130920,815.762024 
	C641.666443,808.486816 642.530212,802.166809 645.403198,796.233765 
	C648.694153,797.035217 646.704468,799.781555 648.189087,800.944092 
	C655.911987,802.597900 656.957825,804.425781 654.582214,812.177124 
	C654.209412,813.393433 654.273376,815.257446 652.506531,815.081787 
	C650.234802,814.856018 651.074646,812.938599 651.237976,811.157715 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M384.833130,804.574951 
	C385.257874,808.716858 384.926819,812.277466 382.124878,815.616333 
	C379.728302,813.126343 381.058197,811.049316 381.352081,809.062500 
	C381.598389,807.397339 382.063171,805.512817 380.058441,804.744446 
	C377.891266,803.913696 376.610931,805.581055 375.738708,807.194641 
	C375.044250,808.479370 374.782043,810.003662 374.370697,811.433838 
	C373.934021,812.952271 374.067413,814.872253 371.635437,815.017212 
	C369.583893,813.306519 371.120483,811.399597 371.394226,809.595215 
	C371.916260,806.154114 372.489807,802.720764 373.047882,799.285156 
	C373.239197,798.107422 373.536835,796.906372 374.836060,796.556274 
	C375.353180,796.416992 376.537872,796.818848 376.541687,796.993530 
	C376.642059,801.587402 380.610565,801.393738 383.392029,802.698303 
	C383.932709,802.951965 384.252136,803.677124 384.833130,804.574951 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M664.123779,808.321777 
	C663.621033,810.632080 664.241699,812.046692 666.369385,812.178772 
	C668.302551,812.298828 669.139771,810.892700 669.904236,809.264099 
	C671.049316,806.824707 669.745544,803.122009 673.462646,801.815552 
	C675.173889,806.379883 673.635437,815.026245 670.938538,815.167725 
	C667.433960,815.351562 663.102112,817.312866 660.679321,812.710266 
	C658.998108,809.516541 660.985657,803.386841 664.207703,801.861267 
	C665.737610,803.916321 663.949768,805.971008 664.123779,808.321777 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M558.660645,805.078613 
	C557.018311,805.085449 555.666809,804.695618 554.283203,805.902405 
	C555.693909,808.342529 561.220398,807.385803 559.844971,812.011353 
	C559.145691,814.362732 557.097900,815.157166 554.812805,815.423706 
	C552.158142,815.733215 549.599365,815.878784 548.276672,812.839233 
	C550.528503,809.879089 553.971375,814.612122 555.958984,811.239990 
	C555.050232,809.485291 552.974792,810.087830 551.696289,809.123718 
	C550.333984,808.096436 548.910461,807.023499 549.726562,805.051147 
	C551.035522,801.887451 553.783936,801.422424 556.764099,801.669739 
	C558.372314,801.803223 560.318787,801.704468 560.107117,804.211426 
	C560.080750,804.523926 559.393982,804.780701 558.660645,805.078613 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M512.215942,808.441772 
	C513.033203,806.074646 510.897980,804.153748 511.791718,802.339539 
	C512.554993,800.790100 513.794861,798.941956 515.695374,798.807861 
	C518.205444,798.630676 518.955933,801.143127 520.417175,802.561584 
	C514.367798,809.034485 514.260193,809.740356 518.789917,813.490051 
	C517.913635,815.891541 516.053955,816.178345 514.084106,815.267273 
	C511.160492,813.915039 511.623566,811.297241 512.215942,808.441772 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M626.625854,804.784363 
	C624.960327,804.423462 623.635376,804.136780 622.742310,805.957153 
	C624.192627,808.266846 629.619690,807.250000 628.348145,811.995483 
	C627.744751,814.247192 625.893433,815.018860 623.837158,815.301514 
	C621.266296,815.654907 618.633545,815.975464 617.123718,813.067139 
	C619.278503,810.395813 622.282410,814.279053 624.114197,811.479492 
	C624.082092,809.370117 622.164917,809.696228 620.936707,809.157227 
	C619.155334,808.375488 618.412842,807.003662 618.821350,805.161926 
	C619.440918,802.368713 621.592407,801.643250 624.100708,801.676147 
	C625.847839,801.699097 628.019287,801.118164 628.811401,803.356995 
	C629.239014,804.565552 628.143311,805.022888 626.625854,804.784363 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M422.615967,811.444092 
	C424.094177,813.570374 423.983948,814.354919 421.985748,814.947998 
	C419.382019,815.720703 416.801910,815.808105 414.401093,813.969971 
	C412.116455,812.220642 412.243500,809.921936 412.769348,807.639343 
	C413.439301,804.731018 415.090302,802.496643 418.229858,801.794861 
	C422.209839,800.905090 424.151306,801.810669 425.535309,805.483643 
	C421.755005,805.297668 416.310303,802.380798 416.105652,809.091736 
	C415.987579,812.962646 419.689178,811.927612 422.615967,811.444092 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M360.656403,814.456360 
	C357.331940,814.997681 358.267151,812.881409 358.502594,811.419312 
	C359.051605,808.009644 359.766296,804.626709 360.359497,801.517761 
	C359.169373,799.401978 356.384155,801.527283 355.601471,799.162292 
	C358.630493,797.120911 365.441956,796.902649 369.766632,798.688110 
	C361.480164,800.813477 362.985718,808.510986 360.656403,814.456360 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M524.714966,805.756714 
	C525.227112,803.084473 525.630798,800.810791 526.078430,798.545837 
	C526.286255,797.494385 526.860168,796.723999 528.022156,796.631958 
	C528.980347,796.556030 529.538513,797.104980 529.857056,797.951355 
	C530.913574,800.758484 527.275085,813.032898 524.474792,815.440613 
	C522.149963,812.226807 524.596497,809.177185 524.714966,805.756714 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M509.182678,798.940186 
	C506.724457,804.295410 507.616425,810.461060 504.142487,815.340881 
	C501.539124,813.576965 502.531403,811.663208 502.810516,810.017761 
	C503.465912,806.153870 504.362305,802.331482 505.083496,798.477722 
	C505.285248,797.399658 505.686737,796.492981 506.761475,796.308411 
	C508.443939,796.019531 509.123535,797.077637 509.182678,798.940186 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M594.306519,813.682129 
	C592.362183,816.112305 591.043884,815.432617 591.366516,813.007324 
	C592.042297,807.927734 593.188171,802.907166 594.249512,797.885193 
	C594.476807,796.809387 595.466064,796.244324 596.580688,796.678284 
	C597.719543,797.121582 597.748474,798.156433 597.519470,799.201782 
	C596.486877,803.914429 595.453491,808.626953 594.306519,813.682129 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M601.313599,807.352173 
	C602.784180,803.472656 601.631287,799.061401 605.061401,795.921814 
	C606.862000,799.791870 604.445801,812.021667 601.504333,815.079468 
	C598.900696,812.697205 601.080994,810.163147 601.313599,807.352173 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M449.721222,809.525269 
	C449.054596,811.693176 449.715973,814.087769 447.126343,814.837402 
	C445.200562,811.120422 447.539185,799.166931 451.250519,795.990173 
	C452.639862,800.844360 449.830627,804.887695 449.721222,809.525269 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M614.464600,796.507080 
	C615.634460,801.949219 612.858948,813.143127 609.227417,815.764038 
	C610.365234,808.588623 609.717834,801.961548 614.464600,796.507080 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M493.617310,813.318481 
	C491.052826,816.354919 490.322205,814.517090 490.559875,812.344910 
	C490.882660,809.394531 491.528259,806.411133 492.529541,803.619202 
	C494.058472,799.356018 497.278503,802.956543 500.079041,802.301941 
	C496.009888,804.997498 493.944794,808.453735 493.617310,813.318481 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
